export const LoginData = [
  {
    user: "guard",
    password: "guard123",
    name: "محمد البلوشي ",
    role: "guard",
  },
  {
    user: "officer",
    password: "officer123",
    name: "سيف الرقيشي",
    role: "officer",
  },
  {
    user: "asad",
    password: "asad",
    name: "العقيد أسعد",
    role: "officer",
  },
  {
    user: "admin",
    password: "admin",
    name: "مدير النظام",
    role: "officer",
  },
  {
    user: "136544",
    password: "95185818",
    name: "اسعد الجابري",
    role: "officer",
  },
  {
    user: "136544",
    password: "95185818",
    name: "اسعد الجابري",
    role: "officer",
  },
];

export const visitsData = [
  {
    name: "محمد البلوشي ",
    id: "123456",
    phone: "99123456",
    startDate: "2021-08-30",
    date: "2021-09-01",
    rank: "رقيب",
    nameofCourse: "دورة فني اسلحه",
  },
  {
    name: "سيف الرقيشي",
    id: "123457",
    phone: "99123457",
    startDate: new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split("T")[0],
    date: new Date().toISOString().split("T")[0],
    rank: "عريف",
    nameofCourse: "دورة فني لحام",
  },
  {
    name: "احمد الرحبي",
    id: "123458",
    phone: "99123458",
    startDate: "2021-08-30",
    date: "2021-09-01",
    rank: "عريف",
    nameofCourse: "دورة الشرطة العسكريه",
  },
  {
    name: "محمد البلوشي ",
    id: "123459",
    phone: "99123459",
    startDate: "2021-08-31",
    date: "2021-09-02",
    rank: "رقيب",
    nameofCourse: "دورة فني اسلحه",
  },
  {
    name: "سيف الرقيشي",
    id: "123460",
    phone: "99123460",
    startDate: "2021-08-31",
    date: "2021-09-02",
    rank: "عريف",
    nameofCourse: "فني مخرطة",
  },
  {
    name: "احمد الرحبي",
    id: "123461",
    phone: "99123461",
    startDate: "2021-08-31",
    date: "2021-09-02",
    rank: "جندي",
    nameofCourse: "دورة الشرطة العسكريه",
  },
  {
    name: "محمد الراجحي",
    id: "123462",
    phone: "99123462",
    startDate: "2021-09-01",
    date: "2021-09-03",
    rank: "رقيب",
    nameofCourse: "دورة كاتب تقدميه",
  },
  {
    name: "سيف الرقيشي",
    id: "123463",
    phone: "059123463",
    startDate: "2021-09-01",
    date: "2021-09-03",
    rank: "عريف",
    nameofCourse: "فني مخرطة",
  },
  {
    name: "احمد الرحبي",
    id: "123464",
    phone: "99123464",
    startDate: new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split("T")[0],
    date: new Date().toISOString().split("T")[0],
    rank: "وكيل",
    nameofCourse: "دورة فني إلكترونيات",
  },
  {
    name: "محمد البلوشي ",
    id: "123465",
    phone: "99123465",
    startDate: "2021-09-02",
    date: "2021-09-04",
    rank: "وكيل",
    nameofCourse: "دورة كاتب تقدميه",
  },
  {
    name: "سيف الرقيشي",
    id: "123466",
    phone: "99123466",
    startDate: "2021-09-02",
    date: "2021-09-04",
    rank: "وكيل",
    nameofCourse: "فني مخرطة",
  },
  {
    name: "أحمد العبري ",
    id: "123466",
    phone: "99123466",
    startDate: "2024-10-28",
    date: "2024-10-30",
    rank: "وكيل",
    nameofCourse: "دورة الشرطة العسكريه",
  },
  {
    name: "خالد السعدي",
    id: "123467",
    phone: "99123467",
    startDate: "2021-09-03",
    date: "2021-09-05",
    rank: "وكيل",
    nameofCourse: "دورة فني اسلحه",
  },
  {
    name: "علي البلوشي",
    id: "123468",
    phone: "99123468",
    startDate: "2021-09-04",
    date: "2021-09-06",
    rank: "وكيل",
    nameofCourse: "دوره فني لحام",
  },
  {
    name: "سالم العلوي",
    id: "123469",
    phone: "99123469",
    startDate: "2021-09-05",
    date: "2021-09-07",
    rank: "وكيل",
    nameofCourse: "فني اكترونيات",
  },
  {
    name: "ناصر الحارثي",
    id: "123470",
    phone: "99123470",
    startDate: "2021-09-06",
    date: "2021-09-08",
    rank: "وكيل",
    nameofCourse: "فني مخرطة",
  },
  {
    name: "عبدالله الشامسي",
    id: "123471",
    phone: "99123471",
    startDate: "2021-09-07",
    date: "2021-09-09",
    rank: "وكيل",
    nameofCourse: "دورة الشرطة العسكريه تأسيسية",
  },
  {
    name: "سعيد البوسعيدي",
    id: "123472",
    phone: "99123472",
    startDate: "2021-09-08",
    date: "2021-09-10",
    rank: "وكيل",
    nameofCourse: "دورة كاتب تقدميه",
  },
];
